import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import n5 from "../../images/news5.jpg";
import NFooter from "../../components/nFooter";

const News = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="iTelaSoft Night 2018"
        description="The annual company gathering was held on the 12th of January, 2018, at Best Western Elyon Colombo."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img news5-bnr">
          <img alt="iTelaSoft Night" src={n5} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">EVENTS | JANUARY 12, 2018</div>
              <h1>iTelaSoft Night 2018</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="content-body">
              <div className="content">
                <p>
                  The annual company gathering was held on the 12th of January,
                  2018, at Best Western Elyon Colombo. The team was able to
                  recap the events that took place in 2017 and discuss the
                  strategies to follow in 2018. The night was filled with fun
                  and excitement, and the team really enjoyed the event!
                </p>
                <p>
                  Addressing the iTelaSoft team and guests, Mr. Indaka Raigama,
                  CEO Global Operations, reminded everyone of the organisation’s
                  small beginning, and how it managed to quickly transform into
                  a medium-sized company in the last few years. He also
                  described some recent achievements of the organisation,
                  thanking everyone who contributed in delivering those
                  cutting-edge solutions. With concluding words from Indaka, he
                  explained the focus areas of the company and the opportunities
                  of working together to achieve those.
                </p>
                <p>
                  One of the guests participating in the event, Dr.
                  Madurapperuma, Senior Lecturer of the Open University and
                  Director of the ICTA Sri Lanka, applauded the dedicated
                  technology focus and innovation demonstrated by iTelaSoft in
                  its short duration. In addition, he shared some words of
                  wisdom, mentioning that a young IT professional should be not
                  only technologically literate, but also culturally literate
                  too! We are no longer an isolated community. Being a solution
                  provider to the global market, it is very important to become
                  culturally sensitive and understand context quickly to become
                  adaptable.
                </p>
                <p>
                  iTelaNight 2018 was a colourful evening with a group of young,
                  vibrant, and passionate individuals sharing their talents and
                  joy with each other.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="itelasoft-2018">
          <NFooter
            prv1=""
            // prv2=""
            next1="/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft"
            next2="/noteworthy/former-navantis-ceo-gives-major-impetus-to-itelasoft"
            // titlel=""
            titler="Former Navantis CEO Joins iTelaSoft"
          />
        </div>
      </div>
    </Layout>
  );
};

export default News;
